import { Map, fromJS } from 'immutable';

import { logger } from '@unitoio/sherlock';

import * as providerTypes from '~/consts/providers';
import { findItemByItemType } from '~/utils/findItemByItemType';
import { normalizeEntitiesById } from '~/utils/normalizeEntitiesById';

export const initialState = Map({
  isLoading: false,
  isLoaded: false,
  entities: Map(),
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case providerTypes.GET_PROVIDERS_REQUEST: {
      return state.merge({
        isLoading: true,
      });
    }

    case providerTypes.GET_PROVIDERS_SUCCESS: {
      const { providers } = action.payload;
      const entities = normalizeEntitiesById(fromJS(providers));
      return state.mergeDeep({
        isLoading: false,
        isLoaded: true,
        entities,
      });
    }

    case providerTypes.GET_PROVIDERS_FAILURE: {
      return state.merge({
        isLoading: false,
      });
    }

    case providerTypes.GET_PROVIDER_CAPABILITIES_REQUEST: {
      return state.merge({
        isLoading: true,
      });
    }

    case providerTypes.GET_PROVIDER_CAPABILITIES_SUCCESS: {
      const { providerName, capabilities } = action.payload;

      const provider = getProviderByName(state, providerName);

      return state.merge({
        isLoading: false,
        isLoaded: true,
        entities: state.get('entities').setIn([provider.get('_id'), 'capabilitiesV3'], fromJS(capabilities)),
      });
    }

    case providerTypes.GET_PROVIDER_CAPABILITIES_FAILURE: {
      return state.merge({
        isLoading: false,
      });
    }

    case providerTypes.GET_PROVIDER_CAPABILITIES_FOR_ITEM_REQUEST: {
      const { providerName, containerId, itemType } = action.meta;

      const provider = getProviderByName(state, providerName);

      return state.merge({
        entities: state.get('entities').setIn(
          [provider.get('_id'), 'capabilitiesForItem', containerId, itemType],
          fromJS({
            isLoading: true,
            isLoaded: false,
          }),
        ),
      });
    }

    case providerTypes.GET_PROVIDER_CAPABILITIES_FOR_ITEM_FAILURE: {
      const { providerName, containerId, itemType } = action.meta;

      const provider = getProviderByName(state, providerName);

      return state.merge({
        entities: state.get('entities').setIn(
          [provider.get('_id'), 'capabilitiesForItem', containerId, itemType],
          fromJS({
            isLoading: false,
            isLoaded: false,
          }),
        ),
      });
    }

    case providerTypes.GET_PROVIDER_CAPABILITIES_FOR_ITEM_SUCCESS: {
      const { providerName, containerId, itemType, capabilities } = action.payload;

      const provider = getProviderByName(state, providerName);

      if (fromJS(capabilities).isEmpty()) {
        logger.warn('Received empty capabilities for:', action.payload);
      }

      return state.setIn(
        ['entities', provider.get('_id'), 'capabilitiesForItem', containerId, itemType],
        fromJS({ isLoading: false, isLoaded: true, capabilities }),
      );
    }

    default: {
      return state;
    }
  }
};

/**
 * SELECTORS
 */

/**
 * Returns the provider object of a given providerId
 * @param {object} state - The providers slice of the redux state
 * @param {string} providerId - The provider id
 * @returns {Immutable.Map} - The provider, empty Immutable.Map if not found
 */
export const getProviderById = (state, providerId) => state.getIn(['entities', providerId], Map());

/**
 * Returns the capabilities of a given provider id
 * Returns a subset of the capabilities if a key is given.
 * If key is undefined, returns the whole capabilities object
 * @param {object} state - The providers slice of the redux state
 * @param {string} providerId - The provider id
 * @param {string} [key] - The subset of the capabilities
 * Valid values are 'terms', 'fields', 'options' or undefined
 * @returns {Immutable.Map} - The providers capabilities defined by the PCD spec
 *                             Empty Immutable.Map if none
 */
// @deprecated favour using: getCapabilitiesByProviderIdV3 instead
export const getCapabilitiesByProviderId = (state, providerId, key) => {
  const fullCapabilities = state.getIn(['entities', providerId, 'capabilities'], Map());

  if (key) {
    // subset of capabilities can sometimes be null, return Immutable.Map() if it's the case
    return fullCapabilities.get(key, Map()) || Map();
  }

  return fullCapabilities;
};

export const getCapabilitiesByProviderIdV3 = (state, providerId, itemType) => {
  const fullCapabilitiesV3 = state.getIn(['entities', providerId, 'capabilitiesV3', 'primaryItems'], Map());
  const firstItem = fullCapabilitiesV3.first();
  return fullCapabilitiesV3.get(itemType, firstItem) ?? Map();
};

export const getCapabilitiesForItem = (state, providerId, containerId, itemType) => {
  const providerObject = getProviderById(state, providerId);

  return providerObject.getIn(['capabilitiesForItem', containerId, itemType, 'capabilities'], Map());
};

export const getSupportedFieldsForItem = (state, providerId, containerId, itemType) => {
  const capabilities = getCapabilitiesForItem(state, providerId, containerId, itemType, 'capabilities');
  const fields = capabilities?.get('fields') || Map();
  return fields.filter((field) => !field.get('unsupported', false));
};

export const getSupportedCustomFieldTypesForItem = (state, providerId, containerId, itemType) => {
  const capabilities = getCapabilitiesForItem(state, providerId, containerId, itemType, 'capabilities');
  const fields = capabilities?.get('customFieldTypes') || Map();
  return fields.filter((field) => !field.get('unsupported', false));
};

export const getItemDefinitionByItemType = (state, providerName, itemType) => {
  const providerObject = getProviderByName(state, providerName);

  if (providerObject.size === 0) {
    return Map();
  }

  const primaryItems = providerObject.getIn(['capabilitiesV3', 'primaryItems']);
  return primaryItems?.first() ? findItemByItemType(primaryItems.first(), itemType) : Map();
};

export const getProviderSupportedItems = (state, providerId) =>
  state.getIn(['entities', providerId, 'capabilitiesV3', 'primaryItems'], Map());

export const getProviderVisibleFields = (state, providerId, itemType, containerId) => {
  const fields = state.getIn(
    ['entities', providerId, 'capabilitiesForItem', containerId, itemType, 'capabilities', 'fields'],
    Map(),
  );
  return fields.filterNot((f) => f.get('unsupported', false)).filterNot((f) => f.get('hidden', false));
};

export const getProvidersDefaultItemAndContainerTypes = (state) => {
  const providerIds = state.get('entities').keySeq();
  return providerIds.reduce((acc, providerId) => {
    const providerCapabilities = getCapabilitiesByProviderIdV3(state, providerId);
    const defaultItemType = providerCapabilities.getIn(['item', 'names', 'native']);
    const defaultContainerType = providerCapabilities.get('containers')?.first()?.getIn(['names', 'native']);

    return defaultItemType && defaultContainerType
      ? acc.set(providerId, { itemType: defaultItemType, containerType: defaultContainerType })
      : acc;
  }, Map());
};

export const getProviderByName = (state, name) =>
  state.get('entities').find((provider) => provider.get('name') === name) || Map();

export const getProviderByConnectorName = (state, connectorName) =>
  state.get('entities').find((provider) => provider.get('connectorName') === connectorName) || Map();

export const getAll = (state) =>
  state.get('entities', Map()).filter((provider) => provider.get('status') === 'enabled');

export const getProviderDisplayNameById = (state, providerId) =>
  state.getIn(['entities', providerId, 'displayName'], '');

export const getProviderConnectorNameById = (state, providerId) =>
  state.getIn(['entities', providerId, 'connectorName'], '');

export const getIsLoading = (state) => state.get('isLoading');

export const getIsLoaded = (state) => state.get('isLoaded');

export const getAreProviderCapabilitiesForItemsLoaded = (
  state,
  providerIdA,
  providerIdB,
  containerIdA,
  containerIdB,
  itemTypeA,
  itemTypeB,
) => {
  const isLoadedA = state.getIn(
    ['entities', providerIdA, 'capabilitiesForItem', containerIdA, itemTypeA, 'isLoaded'],
    false,
  );
  const isLoadedB = state.getIn(
    ['entities', providerIdB, 'capabilitiesForItem', containerIdB, itemTypeB, 'isLoaded'],
    false,
  );
  return isLoadedA && isLoadedB;
};

export const getTaskTermForProvider = (state, providerId, plurality = 'singular') =>
  getCapabilitiesByProviderId(state, providerId, 'terms', Map()).getIn(['task', plurality], 'task');

export const getTermForProviders = (state, providerIdA, providerIdB, term = 'task', plurality = 'singular') => {
  if (!(providerIdA && providerIdB)) {
    return `${term === 'task' ? 'task' : 'project'}${plurality === 'plural' ? 's' : ''}`;
  }

  const termA = getCapabilitiesByProviderId(state, providerIdA, 'terms', Map()).getIn([term, plurality], Map());
  const termB = getCapabilitiesByProviderId(state, providerIdB, 'terms', Map()).getIn([term, plurality], Map());

  if (termA === termB) {
    return termA;
  }
  // Get the generic term based on the plurality
  if (term === 'task') {
    return plurality === 'singular' ? 'task' : 'tasks';
  }
  return plurality === 'singular' ? 'project' : 'projects';
};

export const isCustomFieldsBased = (state, providerName, itemType) => {
  const provider = getProviderByName(state, providerName);
  return provider.getIn(['capabilitiesV3', 'primaryItems', itemType, 'item', 'customFieldBased'], false);
};

// TODO see if we can replace with getSupportedCustomFieldTypesForItem to reduce duplication
export const getCustomFieldsTypeDefinition = (state, providerName, itemType) => {
  const provider = getProviderByName(state, providerName);
  const providerId = provider.get('_id');
  const providerCapabilities = getCapabilitiesByProviderIdV3(state, providerId, itemType);

  return providerCapabilities.getIn(['item', 'customFieldTypes'], Map());
};

export const getByNamePreferredAuthMethod = (state, providerName) => {
  const provider = getProviderByName(state, providerName);
  return provider
    .getIn(['capabilitiesV3', 'authentication', 'authorizations'], Map())
    .findKey((method) => method.get('preferred'));
};

export const getSearchableProviders = (state) =>
  state.get('entities').filter((provider) => {
    const capabilities = getCapabilitiesByProviderId(state, provider.get('_id'));
    return capabilities.getIn(['capabilities', 'container', 'searchable'], false);
  });

export const hasMandatoryConditionalContainers = (state, providerId) =>
  state.getIn(['entities', providerId, 'hasMandatoryConditionalContainers'], false);
