import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { useLogger } from '~/hooks/useLogger';
import * as containerActions from '~/actions/containers';

function hasWarningsOrErrors(container) {
  return (
    container.warnings?.length > 0 ||
    container.richWarnings?.length > 0 ||
    container.errors?.length > 0 ||
    container.richErrors?.length > 0
  );
}

function isSideRestricted({ getContainerPromiseResult, getContainerError, getContainerResponse, side, setError }) {
  let hasRestrictions = false;
  if (getContainerPromiseResult === 'rejected') {
    hasRestrictions = true;
    // Temporary way of conveying the need to convert the API error to a user-friendly message
    // see this RFC for full-blown solution of what we could do instead:
    // https://github.com/unitoio/console/issues/11134
    setError(`container${side}`, { type: 'manual', message: getContainerError.name });
  } else {
    hasRestrictions = hasWarningsOrErrors(getContainerResponse.container);
  }
  return hasRestrictions;
}

// TODO we seem to have a duplicate useFetchContainers hook, in the flowbuilder hooks, clean this up
export function useFetchContainers({
  containerIdA,
  containerIdB,
  containerTypeA,
  containerTypeB,
  providerIdentityIdA,
  providerIdentityIdB,
  itemTypeA,
  itemTypeB,
  linkId,
}) {
  const dispatch = useDispatch();
  const { setError } = useFormContext();
  const { reportException } = useLogger();
  const [haveRestrictions, setContainerHaveRestrictions] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchContainers() {
      try {
        setIsLoading(true);
        const [
          { status: statusA, reason: reasonA, value: responseA },
          { status: statusB, reason: reasonB, value: responseB },
        ] = await Promise.allSettled([
          dispatch(
            containerActions.getContainerById({
              providerIdentityId: providerIdentityIdA,
              containerId: containerIdA,
              containerType: containerTypeA,
              itemType: itemTypeA,
              linkId,
              options: {
                displayError: false,
              },
            }),
          ),
          dispatch(
            containerActions.getContainerById({
              providerIdentityId: providerIdentityIdB,
              containerId: containerIdB,
              containerType: containerTypeB,
              itemType: itemTypeB,
              linkId,
              options: {
                displayError: false,
              },
            }),
          ),
        ]);

        const hasRestrictionsA = isSideRestricted({
          getContainerPromiseResult: statusA,
          getContainerError: reasonA,
          getContainerResponse: responseA,
          side: 'A',
          setError,
        });

        const hasRestrictionsB = isSideRestricted({
          getContainerPromiseResult: statusB,
          getContainerError: reasonB,
          getContainerResponse: responseB,
          side: 'B',
          setError,
        });

        setContainerHaveRestrictions(hasRestrictionsA || hasRestrictionsB);
      } catch (err) {
        reportException(err);
        setError('convert', {
          type: 'manual',
          message: `An error occurred while validating containers ${
            err.message ?? err.name
          }. Please contact support if this persists.`,
        });
        setContainerHaveRestrictions(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetchContainers();
  }, [
    dispatch,
    containerIdA,
    containerIdB,
    containerTypeA,
    containerTypeB,
    itemTypeA,
    itemTypeB,
    providerIdentityIdA,
    providerIdentityIdB,
    reportException,
    linkId,
    setError,
  ]);

  return [haveRestrictions, isLoading];
}
