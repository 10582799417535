import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';

import { FieldsFilters, Flex, tokens } from '@unitoio/mosaic';

import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';
import { useGetContainerPath } from '~/containers/FlowBuilder/pages/ModernRules/hooks/useGetContainerPath';
import {
  StartStopSyncingHeader,
  StartStopSyncingHeaderVariant,
} from '../StartStopSyncingHeader/StartStopSyncingHeader';
import { useGetSides } from '../../Rules/hooks';
import * as formUtils from '../../../utils/form';

export function useSetStopSyncing(side) {
  const { setValue } = useFormContext();
  const stopSyncingPath = `rules.${side}.filters.stopSyncing`;
  const stopSyncingValue = useWatch({ name: stopSyncingPath });
  return [
    stopSyncingValue,
    useCallback(
      (value) => {
        setValue(stopSyncingPath, value, { shouldDirty: true });
      },
      [setValue, stopSyncingPath],
    ),
  ];
}

export const StopSyncing = ({ side, loadingState }) => {
  const isAutoSaving = loadingState === formUtils.loadingStates.SAVING;

  const { [side]: currentSide } = useGetSides();
  const [containerA, containerB] = useGetContainers();
  const container = side === 'A' ? containerA : containerB;
  const [stopSyncingValue, setStopSyncing] = useSetStopSyncing(side);
  const forgedContainerPath = useGetContainerPath(currentSide);

  return (
    <Flex vertical gap={tokens.spacing.s4}>
      <StartStopSyncingHeader
        side={side}
        containerName={container.get('displayName')}
        variant={StartStopSyncingHeaderVariant.StopSyncing}
      />
      <FieldsFilters
        firstRowPrefix="IF"
        combinator="or"
        disabled={isAutoSaving}
        credentialId={currentSide.providerCredentialId}
        containerPath={forgedContainerPath}
        onChange={setStopSyncing}
        value={stopSyncingValue}
        itemType={currentSide.itemType}
      />
    </Flex>
  );
};

StopSyncing.propTypes = {
  side: PropTypes.oneOf(['A', 'B']),
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
};
