import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex, Icon, NewSelect, NewTooltip, ProviderIcon, tokens, Typography } from '@unitoio/mosaic';

import { useGetSides } from '~/containers/FlowBuilder/pages/Rules/hooks';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';

const IconTooltip = styled(Icon)`
  cursor: pointer;
`;

export const StartStopSyncingHeaderVariant = {
  StartSyncing: 'StartSyncing',
  StopSyncing: 'StopSyncing',
};

export function StartStopSyncingHeader({ side, containerName, variant }) {
  const { A, B } = useGetSides();
  const currentSide = side === 'A' ? A : B;
  const oppositeSide = side === 'A' ? B : A;
  const { itemType, providerName, containerId } = currentSide;
  const {
    itemType: oppositeItemType,
    providerName: oppositeProviderName,
    containerId: oppositeContainerId,
  } = oppositeSide;

  const SideSingularTerm = (
    <ProviderTermsByName
      pcdv3
      termKey="tasks"
      plurality="singular"
      itemTypeA={itemType}
      providerNameA={providerName}
      containerIdA={containerId}
      containerIdB={oppositeContainerId}
    />
  );

  const SidePluralTerm = (
    <ProviderTermsByName
      pcdv3
      termKey="tasks"
      plurality="plural"
      itemTypeA={itemType}
      providerNameA={providerName}
      containerIdA={containerId}
      containerIdB={oppositeContainerId}
    />
  );

  const BothSidesPluralTerms = (
    <ProviderTermsByName
      pcdv3
      termKey="tasks"
      plurality="plural"
      itemTypeA={itemType}
      itemTypeB={oppositeItemType}
      providerNameA={providerName}
      providerNameB={oppositeProviderName}
      containerIdA={containerId}
      containerIdB={oppositeContainerId}
    />
  );
  return (
    <Flex align="center">
      <Flex align="center" justify="flex-start" gap={tokens.spacing.s3} flex="1">
        <NewSelect
          variant="readOnly"
          labelInValue
          labelRender={() =>
            variant === StartStopSyncingHeaderVariant.StartSyncing ? (
              <Typography>Start syncing {SidePluralTerm} from</Typography>
            ) : (
              <Typography>Stop syncing {SidePluralTerm} in</Typography>
            )
          }
          value={{ value: 'doesNotMatter', label: '' }}
        />
        <NewSelect
          variant="readOnly"
          labelInValue
          labelRender={() => (
            <Flex align="center" gap={tokens.spacing.s3}>
              <ProviderIcon name={providerName} size="small" />
              <Typography>{containerName}</Typography>
            </Flex>
          )}
          value={{ value: 'doesNotMatter', label: '' }}
        />
      </Flex>
      {variant === StartStopSyncingHeaderVariant.StopSyncing && (
        <Flex style={{ padding: `0 ${tokens.spacing.s3}` }}>
          <NewTooltip
            placement="top"
            title={
              <Typography>
                Control when {BothSidesPluralTerms} stop syncing. The sync will resume automatically if a{' '}
                {SideSingularTerm} no longer meets any stop rules and meets the start rules.
              </Typography>
            }
          >
            <IconTooltip
              title="Stop syncing tooltip"
              name="info-circle"
              kind={Icon.KINDS.SOLID}
              color={tokens.colors.content.info.default}
            />
          </NewTooltip>
        </Flex>
      )}
    </Flex>
  );
}

StartStopSyncingHeader.propTypes = {
  side: PropTypes.string.isRequired,
  containerName: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(StartStopSyncingHeaderVariant)).isRequired,
};
