import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { FieldValuesConfigurator } from '@unitoio/mosaic';

import { otherSide } from '~/utils/otherSide';
import { useGetProviderTerms } from '~/containers/FlowBuilder/hooks/useGetProviderTerms';
import { useGetSides } from '../../Rules/hooks';
import { useGetContainerPath } from '../hooks/useGetContainerPath';

export const StartDefaultValues = ({ side: originalSide }) => {
  const { register, watch, setValue } = useFormContext();

  // we flip the sides, we set default values for B on the A side in the UI
  const side = otherSide(originalSide);

  const actionsPath = `${side}.actions`;
  const formValues = watch(actionsPath);

  const { append, remove, fields } = useFieldArray({
    name: actionsPath,
  });

  const { [side]: currentSide } = useGetSides();
  const containerPath = useGetContainerPath(currentSide);
  const { containerName, providerName, itemType } = currentSide;
  const itemTerms = useGetProviderTerms(providerName, itemType);

  const handleOnChange = useCallback(
    (name, value) => {
      setValue(name, value, { shouldDirty: true });
    },
    [setValue],
  );

  return (
    <FieldValuesConfigurator
      credentialId={currentSide.providerCredentialId}
      containerPath={containerPath}
      containerName={containerName}
      providerName={providerName}
      onChange={handleOnChange}
      itemType={itemType}
      defaultValue={fields}
      itemTerm={itemTerms.singular ?? itemType}
      control={{
        register,
        name: actionsPath,
        value: formValues,
        remove,
        add: (initialValues = { fieldId: '', value: '' }) => append(initialValues, { shouldDirty: false }),
      }}
    />
  );
};

StartDefaultValues.propTypes = {
  side: PropTypes.oneOf(['A', 'B']),
};
