import React from 'react';
import PropTypes from 'prop-types';

import { tokens, Icon, Flex, NewTypography } from '@unitoio/mosaic';
import { useSetStopSyncing } from './StopSyncing';

export const StopSyncingLabel = ({ icon, side }) => {
  const [stopSyncingValues] = useSetStopSyncing(side);
  const stopSyncingValuesTotal = stopSyncingValues?.$or?.length ? `(${stopSyncingValues?.$or?.length})` : '';
  return (
    <Flex
      align="center"
      gap={tokens.spacing.s3}
      style={{ padding: `${tokens.spacing.s3} 0`, borderColor: tokens.colors.content.neutral.n10 }}
    >
      {icon && <Icon name={icon} />}{' '}
      <NewTypography.Text type="secondary">Stop syncing conditions {stopSyncingValuesTotal}</NewTypography.Text>
    </Flex>
  );
};

StopSyncingLabel.propTypes = {
  icon: PropTypes.string,
  side: PropTypes.oneOf(['A', 'B']),
};
