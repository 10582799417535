import React from 'react';
import PropTypes from 'prop-types';

import { Skeleton, Space, tokens } from '@unitoio/mosaic';
import { PageContainer } from '../PageContainer/PageContainer';
import { ModernRulesHeader } from './ModernRulesHeader';
import { ModernRuleSide } from './ModernRuleSide';
import { statuses } from '../Rules/consts';
import * as formUtils from '../../utils/form';
import { ManageDuplicates } from '../../components/ManageDuplicates';

// TODO: the loading state is tied to the flow builder master hook, can't extract it easily so still drilling it down
export const ModernRules = ({ loadingState }) => {
  const isLoading = [statuses.INITIAL, statuses.LOADING, statuses.STANDBY].includes(loadingState);

  return (
    <PageContainer>
      <ModernRulesHeader />

      <Skeleton loading={isLoading}>
        <Space style={{ marginBottom: tokens.spacing.s4 }}>
          <ManageDuplicates />
        </Space>

        <ModernRuleSide side="A" loadingState={loadingState} />
        <ModernRuleSide side="B" loadingState={loadingState} />
      </Skeleton>
    </PageContainer>
  );
};

ModernRules.propTypes = {
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
};
