import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormProvider } from 'react-hook-form';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingPlaceholder, tokens } from '@unitoio/mosaic';

import * as fieldActions from '~/actions/fields';
import * as featureTypes from '~/consts/features';
import * as routes from '~/consts/routes';
import * as linkTypes from '~/consts/link';
import * as trackingTypes from '~/consts/tracking';
import {
  getFeatureFlagValue,
  getLinkOrganizationId,
  getLinkState,
  getSelectedOrganizationId,
  isUserSiteAdmin,
  organizationNeedsPayment,
} from 'reducers';

import * as formUtils from '~/containers/FlowBuilder/utils/form';
import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { OrgNeedsPayment } from '~/components/OrgNeedsPayment/OrgNeedsPayment';
import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';
import { getLinkStateTrackingJourney } from '~/utils/forms';

import { FlowBuilderErrorFunnel } from './components/FlowBuilderErrorFunnel';
import { FlowBuilderHeader } from './components/FlowBuilderHeader/FlowBuilderHeader';
import { FlowBuilderWithErrorBoundary } from './components/FlowBuilderWithErrorBoundary/FlowBuilderWithErrorBoundary';
import { MappedFieldsRouter } from './components/MappedFieldsRouter';
import { SaveChangesModal } from './components/SaveChangesModal';
import { useFlowBuilderGetForm } from './hooks/useFlowBuilderGetForm';
import { ConnectTools } from './pages/ConnectTools';
import { FlowDirection } from './pages/FlowDirection';
import { Guide } from './pages/Guide/Guide';
import { PageContainer } from './pages/PageContainer/PageContainer';
import { Rules } from './pages/Rules/Rules';
import { ModernRules } from './pages/ModernRules/ModernRules';

function useTrackStartEvent(linkState) {
  const trackEvent = useTrackEvent();
  const mode = getLinkStateTrackingJourney(linkState);
  const { reportInfo } = useLogger();

  useEffect(() => {
    trackEvent(trackingTypes.START);
    reportInfo(`START ${mode} ${trackingTypes.PAGE.FLOW_BUILDER} funnel`, {
      funnel: { action: trackingTypes.START, mode },
    });
  }, [mode, reportInfo, trackEvent]);
}

function useResetStore() {
  const dispatch = useDispatch();
  // when navigating from one flow to another with the flow builder
  // though the local state of the FlowBuilder component is cleared and reinitialized
  // every time, the flow builder still relies on some values stored in the redux store
  // (like the fields store), and this can cause some issues while loading values for FieldSelectValues
  // (where values previously fetched for a different flow might still live!)
  // for this reason, we have to clear the fields store when unmounting.
  useEffect(
    () => () => {
      dispatch(fieldActions.resetStore());
    },
    [dispatch],
  );
}

const FlowBuilder = ({ match }) => {
  /* initializing the form */
  const { formData, loadingState, setLoadingState, isDuplicating, hasChanged, ...formMethods } =
    useFlowBuilderGetForm();
  const {
    handleSubmit,
    getValues,
    formState: { errors },
    register,
  } = formMethods;

  /* FlowBuilder specific hooks */
  useTrackStartEvent(formData.state);
  useResetStore();

  const hasAccessToModernRules = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.MODERN_RULES_PAGE),
  );

  const isDraft = formData.state === linkTypes.LINK_STATES.DRAFT;
  const hasIncompleteOrInvalidPages =
    errors[trackingTypes.MODULE.TOOL_SELECTION]?.invalidProviderIdentityA ||
    errors[trackingTypes.MODULE.TOOL_SELECTION]?.invalidProviderIdentityB ||
    errors[trackingTypes.MODULE.RULES]?.mandatoryDeepFiltersMissingSideA ||
    errors[trackingTypes.MODULE.RULES]?.mandatoryDeepFiltersMissingSideB ||
    errors[trackingTypes.MODULE.RULES]?.incompleteItemFieldAssociationsA ||
    errors[trackingTypes.MODULE.RULES]?.incompleteItemFieldAssociationsB ||
    errors[trackingTypes.MODULE.MAPPINGS]?.missingMandatoryFieldsA ||
    errors[trackingTypes.MODULE.MAPPINGS]?.missingMandatoryFieldsB ||
    errors[trackingTypes.MODULE.MAPPINGS]?.incompleteFieldMappingGroup ||
    errors[trackingTypes.MODULE.MAPPINGS]?.lastAssociationNotCompleted;

  return (
    <FormProvider {...formMethods} setLoadingState={setLoadingState} handleSubmit={handleSubmit}>
      <SaveChangesModal isDraft={isDraft} loadingState={loadingState} hasChanged={hasChanged} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register('isAutoSync', { value: getValues('isAutoSync') ?? true })} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register('lazyResync', { value: getValues('lazyResync') ?? true })} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register('duplicateLinkId')} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register('name')} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register('syncDirection')} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register('organizationId')} />

      <Switch>
        <Route
          path={`${match.path}/:pageName(${Object.values(routes.FLOW_BUILDER_PAGES).join('|')})`}
          render={(routeProps) => {
            if (loadingState === formUtils.loadingStates.INITIAL) {
              return (
                <>
                  <FlowBuilderHeader disableSave={!hasChanged || !!hasIncompleteOrInvalidPages} />
                  <PageContainer
                    sidebar={() => (
                      <LoadingPlaceholder borderRadius={tokens.spacing.s2} width="100%" height={tokens.spacing.s9} />
                    )}
                  >
                    <LoadingPlaceholder borderRadius={tokens.spacing.s2} width="100%" height={tokens.spacing.s9} />
                  </PageContainer>
                </>
              );
            }

            const flowBuilderComponents = {
              [routes.FLOW_BUILDER_PAGES.TOOL_SELECTION]: ConnectTools,
              [routes.FLOW_BUILDER_PAGES.FLOW_DIRECTION]: FlowDirection,
              [routes.FLOW_BUILDER_PAGES.RULES]: hasAccessToModernRules ? ModernRules : Rules,
              [routes.FLOW_BUILDER_PAGES.MAPPINGS]: MappedFieldsRouter,
              [routes.FLOW_BUILDER_PAGES.GUIDE]: Guide,
            };
            const flowBuilderTrackingModules = {
              [routes.FLOW_BUILDER_PAGES.TOOL_SELECTION]: trackingTypes.MODULE.TOOL_SELECTION,
              [routes.FLOW_BUILDER_PAGES.FLOW_DIRECTION]: trackingTypes.MODULE.FLOW_DIRECTION,
              [routes.FLOW_BUILDER_PAGES.RULES]: trackingTypes.MODULE.RULES,
              [routes.FLOW_BUILDER_PAGES.MAPPINGS]: trackingTypes.MODULE.MAPPINGS,
              [routes.FLOW_BUILDER_PAGES.GUIDE]: trackingTypes.MODULE.GUIDE,
            };
            const FlowBuilderPage = flowBuilderComponents[routeProps.match.params.pageName] ?? Guide;

            return (
              <TrackingFunnel contextName={flowBuilderTrackingModules[routeProps.match.params.pageName]}>
                <FlowBuilderErrorFunnel pageName={flowBuilderTrackingModules[routeProps.match.params.pageName]}>
                  <FlowBuilderHeader disableSave={!hasChanged || !!hasIncompleteOrInvalidPages} />
                  <FlowBuilderPage {...routeProps} isDuplicating={isDuplicating} loadingState={loadingState} />
                </FlowBuilderErrorFunnel>
              </TrackingFunnel>
            );
          }}
        />
        <Redirect to={`${match.url}/${routes.BASE_PATHS.GUIDE}`} />
      </Switch>
    </FormProvider>
  );
};

FlowBuilder.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({ workflowId: PropTypes.string, linkId: PropTypes.string }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({ replace: PropTypes.func.isRequired }).isRequired,
};

export const FlowBuilderWithTrackingFunnel = (props) => {
  const { match } = props;
  const { linkId } = match.params;
  const linkOrganizationId = useSelector((state) => getLinkOrganizationId(state, linkId));
  const selectedOrganizationId = useSelector((state) => getSelectedOrganizationId(state));
  const organizationId = linkOrganizationId ?? selectedOrganizationId;
  const linkOrgNeedsPayment = useSelector((state) => organizationNeedsPayment(state, organizationId));
  const isSiteAdmin = useSelector((state) => isUserSiteAdmin(state));
  const linkState = useSelector((state) => getLinkState(state, linkId)) ?? linkTypes.LINK_STATES.DRAFT;

  return (
    <TrackingFunnel contextName={getLinkStateTrackingJourney(linkState)}>
      <TrackingFunnel contextName={trackingTypes.PAGE.GUIDE}>
        {linkOrgNeedsPayment && !isSiteAdmin ? (
          <OrgNeedsPayment organizationId={organizationId} />
        ) : (
          <FlowBuilderWithErrorBoundary {...props}>
            <FlowBuilder {...props} />
          </FlowBuilderWithErrorBoundary>
        )}
      </TrackingFunnel>
    </TrackingFunnel>
  );
};

FlowBuilderWithTrackingFunnel.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({ workflowId: PropTypes.string, linkId: PropTypes.string }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({ replace: PropTypes.func.isRequired }).isRequired,
};

export { FlowBuilderWithTrackingFunnel as FlowBuilder };
