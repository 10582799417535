import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';

import { Flex, FieldsFilters, tokens } from '@unitoio/mosaic';

import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';
import { useGetContainerPath } from '~/containers/FlowBuilder/pages/ModernRules/hooks/useGetContainerPath';
import {
  StartStopSyncingHeader,
  StartStopSyncingHeaderVariant,
} from '../StartStopSyncingHeader/StartStopSyncingHeader';
import { useGetSides } from '../../Rules/hooks';
import * as formUtils from '../../../utils/form';

export function useSetStartSyncing(side) {
  const { setValue } = useFormContext();
  const startSyncingPath = `rules.${side}.filters.startSyncing`;
  const startSyncingValue = useWatch({ name: startSyncingPath });
  return [
    startSyncingValue,
    useCallback(
      (value) => {
        setValue(startSyncingPath, value, { shouldDirty: true });
      },
      [setValue, startSyncingPath],
    ),
  ];
}

export const StartSyncing = ({ side, loadingState }) => {
  const isAutoSaving = loadingState === formUtils.loadingStates.SAVING;

  const { [side]: currentSide } = useGetSides();
  const [containerA, containerB] = useGetContainers();
  const container = side === 'A' ? containerA : containerB;
  const [startSyncingValue, setStartSyncing] = useSetStartSyncing(side);
  const containerPath = useGetContainerPath(currentSide);

  return (
    <Flex vertical gap={tokens.spacing.s4}>
      <StartStopSyncingHeader
        side={side}
        containerName={container.get('displayName')}
        variant={StartStopSyncingHeaderVariant.StartSyncing}
      />
      <FieldsFilters
        firstRowPrefix="IF"
        disabled={isAutoSaving}
        credentialId={currentSide.providerCredentialId}
        containerPath={containerPath}
        onChange={setStartSyncing}
        value={startSyncingValue}
        itemType={currentSide.itemType}
      />
    </Flex>
  );
};

StartSyncing.propTypes = {
  side: PropTypes.oneOf(['A', 'B']),
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
};
