import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Icon, Tooltip } from '@unitoio/mosaic';
import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import { connect } from 'react-redux';
import { DropdownItem } from '~/components/Dropdown/Dropdown';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { getLinkById, getLinkProviderNameBySide, getProviderCapabilitiesV3, getUserId } from '~/reducers';

const DuplicateDropdownItemComponent = ({ link, linkId, capabilitiesV3A, capabilitiesV3B, isSameUser }) => {
  const trackEvent = useTrackEvent();

  const isDuplicableSync =
    !link.get('isSuspended') && link.get('kind') === linkTypes.KIND.MIRROR_SYNC && !link.get('restricted', false);

  if (!isDuplicableSync) {
    return null;
  }

  const isMergeBasedFlow =
    capabilitiesV3A.getIn(['item', 'canMerge'], false) || capabilitiesV3B.getIn(['item', 'canMerge'], false);

  const tooltipContent = isMergeBasedFlow
    ? 'Duplicating flows with contact based tools is not supported yet.'
    : "You'll need access to the accounts of tools associated with this flow in order to duplicate it.";

  const duplicatePath = routes.getDuplicateFlowBuilderRoute(linkId, 'tool-selection');

  return (
    <DropdownItem
      disabled={isMergeBasedFlow || !isSameUser}
      to={duplicatePath}
      onClick={() =>
        trackEvent(trackingTypes.USER_DASHBOARD_EVENTS.ACTION_NAME, {
          action_name: trackingTypes.USER_DASHBOARD_EVENTS.ACTIONS.DUPLICATE_LINK,
        })
      }
    >
      <Tooltip content={tooltipContent} as="div">
        <Icon name="clone" fixedWidth /> Duplicate
      </Tooltip>
    </DropdownItem>
  );
};

const mapStateToProps = (state, ownProps) => {
  const link = getLinkById(state, ownProps.linkId);

  const currUserId = getUserId(state);
  const linkCreatorUserId = link.getIn(['user', '_id']);
  const isSameUser = currUserId === linkCreatorUserId;

  const providerNameA = getLinkProviderNameBySide(state, { containerSide: 'A', linkId: ownProps.linkId });
  const providerNameB = getLinkProviderNameBySide(state, { containerSide: 'B', linkId: ownProps.linkId });

  const capabilitiesV3A = getProviderCapabilitiesV3(state, {
    providerName: providerNameA,
    itemType: link.getIn(['A', 'itemType']),
  });
  const capabilitiesV3B = getProviderCapabilitiesV3(state, {
    providerName: providerNameB,
    itemType: link.getIn(['B', 'itemType']),
  });

  return {
    link,
    capabilitiesV3A,
    capabilitiesV3B,
    isSameUser,
  };
};

DuplicateDropdownItemComponent.propTypes = {
  link: PropTypes.instanceOf(Map).isRequired,
  linkId: PropTypes.string.isRequired,
  capabilitiesV3A: PropTypes.instanceOf(Map).isRequired,
  capabilitiesV3B: PropTypes.instanceOf(Map).isRequired,
  isSameUser: PropTypes.bool.isRequired,
};

export const DuplicateDropdownItem = connect(mapStateToProps)(DuplicateDropdownItemComponent);
