import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { List } from 'immutable';

import { Box, Icon, tokens, Tooltip, Typography, Radio } from '@unitoio/mosaic';

import {
  getField,
  getOrganizationPlanFeaturesWithUsage,
  getCapabilitiesFieldTypes,
  isOnFreeTrial,
  getSelectedOrganizationId,
  isProviderItemCustomFieldBased,
  getFeatureFlagValue,
} from 'reducers';
import * as featureTypes from '~/consts/features';
import * as fieldTypes from '~/consts/fields';
import { capitalize } from '~/utils/capitalize';
import { otherSide as fromOtherSide } from '~/utils/otherSide';
import { PlanFeature } from '~/components/PlanFeature/PlanFeature';

import { useGetCapabilitiesV3 } from '../hooks/useGetCapabilitiesV3';
import { PCDFieldsSelect, displayTypes } from './PCDFieldsSelect';

const LabelEllipsis = styled(Typography)`
  white-space: nowrap;
`;

const MaxWidthBox = styled(Box)`
  max-width: 100%;
`;
const MinWidthBox = styled(Box)`
  min-width: 0;
`;

const GrowingBox = styled(Box)`
  flex: 1 1 0px;
  max-width: 100%;
  min-width: 0;
`;

export function isFieldCompatible({
  fieldEntity,
  otherFieldEntity,
  containerSide,
  target,
  capabilitiesFieldTypes,
  isSingleAndMultiMappingFlagEnabled,
}) {
  if (fieldEntity?.isEmpty() || otherFieldEntity?.isEmpty()) {
    return true;
  }

  const fieldEntitySemantic = fieldEntity.get('semantic');
  const otherFieldEntitySemantic = otherFieldEntity.get('semantic');

  const fieldEntityId = fieldEntity.get('fieldId');
  const otherFieldEntityId = otherFieldEntity.get('fieldId');

  const fieldEntityReadOnly = fieldEntity.get('readOnlyOnUpdate', false);
  const otherFieldEntityReadOnly = otherFieldEntity.get('readOnlyOnUpdate', false);

  const fieldEntityType = fieldEntity.get('type');
  const otherFieldEntityType = otherFieldEntity.get('type');

  const fieldEntityIsArray = fieldEntity.get('isArray');
  const otherFieldEntityIsArray = otherFieldEntity.get('isArray');

  const bothFieldsAreUserType =
    fieldEntityType === fieldTypes.TYPES.USER && otherFieldEntityType === fieldTypes.TYPES.USER;
  const bothFieldsAreAssignee =
    fieldEntitySemantic?.startsWith('assignee') && otherFieldEntitySemantic?.startsWith('assignee');

  const fieldsAreMultiAndSingle =
    fieldEntityIsArray && otherFieldEntityIsArray && fieldEntityIsArray !== otherFieldEntityIsArray;

  if ([fieldEntityId, otherFieldEntityId].includes(fieldTypes.DESCRIPTION_FOOTER)) {
    return isFieldCompatibleWithDescriptionFooter(
      fieldEntityId,
      otherFieldEntityId,
      fieldEntitySemantic,
      otherFieldEntitySemantic,
    );
  }

  if (
    fieldEntitySemantic === fieldTypes.SEMANTIC.ATTACHMENTS &&
    otherFieldEntitySemantic === fieldTypes.SEMANTIC.ATTACHMENTS
  ) {
    const fieldCanBinaryCopy = fieldEntity.getIn(['itemType', 'canBinaryCopy']);
    const otherFieldCanBinaryCopy = otherFieldEntity.getIn(['itemType', 'canBinaryCopy']);

    return fieldCanBinaryCopy && otherFieldCanBinaryCopy;
  }

  if (fieldEntityReadOnly && otherFieldEntityReadOnly) {
    return false;
  }

  if (fieldsAreMultiAndSingle && !isSingleAndMultiMappingFlagEnabled && !bothFieldsAreAssignee) {
    return false;
  }

  if (fieldsAreMultiAndSingle && isSingleAndMultiMappingFlagEnabled && !bothFieldsAreUserType) {
    return false;
  }

  const otherSide = fromOtherSide(containerSide);
  if ((fieldEntityReadOnly && target === containerSide) || (otherFieldEntityReadOnly && target === otherSide)) {
    return false;
  }

  if (fieldEntityType === fieldTypes.TYPES.ITEM && otherFieldEntityType === fieldTypes.TYPES.ITEM) {
    return !!fieldEntitySemantic && areSemanticsCompatible(fieldEntity, otherFieldEntity);
  }

  const isItemToStringMapping = [
    [
      fieldEntityType === fieldTypes.TYPES.ITEM && fieldEntitySemantic === fieldTypes.SEMANTIC.ATTACHMENTS,
      otherFieldEntityType === fieldTypes.TYPES.STRING && !otherFieldEntityReadOnly,
    ],
    [
      fieldEntityType === fieldTypes.TYPES.STRING && !fieldEntityReadOnly,
      otherFieldEntityType === fieldTypes.TYPES.ITEM && otherFieldEntitySemantic === fieldTypes.SEMANTIC.ATTACHMENTS,
    ],
  ].some(([a, b]) => a && b);

  if (isItemToStringMapping) {
    return true;
  }

  const fieldConvertibleToOtherField = capabilitiesFieldTypes
    .getIn([fieldEntityType, 'convertibleTo'], List())
    .includes(otherFieldEntityType);

  const otherFieldConvertibleToField = capabilitiesFieldTypes
    .getIn([otherFieldEntityType, 'convertibleTo'], List())
    .includes(fieldEntityType);

  return (
    fieldConvertibleToOtherField ||
    otherFieldConvertibleToField ||
    (!!fieldEntitySemantic && areSemanticsCompatible(fieldEntity, otherFieldEntity))
  );
}

function isFieldCompatibleWithDescriptionFooter(
  fieldEntityId,
  otherFieldEntityId,
  fieldEntitySemantic,
  otherFieldEntitySemantic,
) {
  const incompatibleSemanticWithLinkBlock = [
    fieldTypes.SEMANTIC.SUBTASKS,
    fieldTypes.SEMANTIC.TASKS,
    fieldTypes.SEMANTIC.COMMENTS,
    fieldTypes.SEMANTIC.COMMENTS_PUBLIC,
  ];

  return (
    // 2 description footers are not compatible together
    // Some fields shouldn't be compatible with a description footer
    fieldEntityId !== otherFieldEntityId &&
    ![fieldEntitySemantic, otherFieldEntitySemantic].some((semantic) =>
      incompatibleSemanticWithLinkBlock.includes(semantic),
    )
  );
}

function areSemanticsCompatible(fieldEntity, otherFieldEntity) {
  const fieldEntitySemantic = fieldEntity.get('semantic');
  const otherFieldEntitySemantic = otherFieldEntity.get('semantic');

  const areSameSemantic = fieldEntitySemantic === otherFieldEntitySemantic;

  const areWorkflowSemantic =
    fieldEntitySemantic?.startsWith(fieldTypes.SEMANTIC.WORKFLOW) &&
    otherFieldEntitySemantic?.startsWith(fieldTypes.SEMANTIC.WORKFLOW);

  const commentSemantics = [fieldTypes.SEMANTIC.COMMENTS, fieldTypes.SEMANTIC.COMMENTS_PUBLIC];
  const areCommentSemantic =
    commentSemantics.includes(fieldEntitySemantic) && commentSemantics.includes(otherFieldEntitySemantic);

  return areSameSemantic || areWorkflowSemantic || areCommentSemantic;
}

function isOptionDisabled({
  fieldEntity,
  isEntityAlreadyMapped,
  containerSide,
  isCustomFieldLimitReached,
  isFieldMappingLimitReached,
  otherFieldEntity,
  target,
  capabilitiesFieldTypes,
  orgPlanFeatureUsage,
  isSingleAndMultiMappingFlagEnabled,
}) {
  const hasAccessToSubtask = orgPlanFeatureUsage.getIn(['subtask', 'value']);
  const fieldId = fieldEntity.get('fieldId');
  const fieldKind = fieldEntity.get('kind');
  const otherFieldId = otherFieldEntity?.get('fieldId');
  const otherFieldKind = otherFieldEntity?.get('kind');

  if (!fieldEntity.get('semantic')?.startsWith(fieldTypes.SEMANTIC.WORKFLOW) && isFieldMappingLimitReached) {
    return { disabled: true, disabledText: 'Limit reached' };
  }

  if (isEntityAlreadyMapped(fieldId, fieldKind, containerSide, otherFieldId, otherFieldKind)) {
    return { disabled: true, disabledText: 'Mapped' };
  }

  if (
    !isFieldCompatible({
      fieldEntity,
      otherFieldEntity,
      containerSide,
      target,
      capabilitiesFieldTypes,
      isSingleAndMultiMappingFlagEnabled,
    })
  ) {
    return { disabled: true, disabledText: 'Incompatible' };
  }

  if (
    // - if custom field limit reached don't allow more mapping ($$$)
    (fieldKind === fieldTypes.KINDS.CUSTOM_FIELD && isCustomFieldLimitReached) ||
    // - if subtask syncing is available on plan ($$$)
    (fieldEntity.get('semantic') === fieldTypes.SEMANTIC.SUBTASKS && !hasAccessToSubtask)
  ) {
    return { disabled: true, disabledText: 'Excluded from plan' };
  }

  return { disabled: false, disabledText: undefined };
}

const renderHelpIcon = (content, color) => (
  <Box p={[0, 0, 0, tokens.spacing.s3]}>
    <Tooltip placement="top" content={content}>
      <Icon name="question-circle" kind={Icon.KINDS.SOLID} color={color} aria-label={content} />
    </Tooltip>
  </Box>
);

const formatLabel = (option, xxx) => {
  const { otherFieldType, target, containerSide, providerName, isOrgTrialing, customFieldsBased, itemNamePlural } = xxx;

  // For retro compatibily. PCDv3 term isn't the same as the old one from capabilities
  const richTextTypes = ['richtext', 'rich_text'];
  const showRichTextWarningIcon =
    richTextTypes.includes(option.type) && !richTextTypes.includes(otherFieldType) && target !== containerSide;

  const showCustomFieldIcon = option.kind === fieldTypes.KINDS.CUSTOM_FIELD && !customFieldsBased;

  const showEmailAddressFieldIcon = option.value === fieldTypes.EMAIL_ADDRESSES;

  const showPhonesFieldIcon = option.value === fieldTypes.PHONES;

  const showChecklistIcon = option.value === fieldTypes.CHECKLISTS;

  const showStatusIcon = option.semantic === fieldTypes.SEMANTIC.WORKFLOW_STATUS;

  const showColumnIcon = option.value === fieldTypes.COLUMN;

  const showSubtaskIcon = option.value === fieldTypes.SUBTASKS;

  const showUserFieldIcon = option.type === fieldTypes.TYPES.USER;

  return (
    <MaxWidthBox justifyContent="center" alignItems="center">
      <MinWidthBox m={[0, 0, 0, tokens.spacing.s3]}>
        <LabelEllipsis textOverflow="ellipsis">{option.label}</LabelEllipsis>
      </MinWidthBox>
      {showCustomFieldIcon && (
        <PlanFeature name={featureTypes.FEATURES.CUSTOM_FIELD}>
          {(_, limit) => {
            const customFieldMessage = isOrgTrialing
              ? `This is a custom field created in ${capitalize(
                  providerName,
                )}. Your free trial allows an unlimited number of custom field mappings.`
              : `This is a custom field created in ${capitalize(
                  providerName,
                )}. Based on your plan, you can map ${limit} custom fields in this flow.`;
            return renderHelpIcon(customFieldMessage, tokens.colors.content.info.default);
          }}
        </PlanFeature>
      )}
      {showEmailAddressFieldIcon &&
        renderHelpIcon(
          'If you have multiple emails associated to a contact, Unito will only sync the first on the list.',
          tokens.colors.content.neutral.n40,
        )}
      {showPhonesFieldIcon &&
        renderHelpIcon(
          'If you have multiple phone numbers associated to a contact, Unito will only sync the first on the list.',
          tokens.colors.content.neutral.n40,
        )}
      {showRichTextWarningIcon &&
        renderHelpIcon(
          `You may lose rich text formatting by mapping the ${option.label} to a field that does not support it.`,
          tokens.colors.content.neutral.n20,
        )}
      {(showChecklistIcon || showSubtaskIcon) &&
        renderHelpIcon(
          `${option.label} creation will always follow your flow’s direction. This mapping only affects the direction in which updates will be made.`,
        )}
      {showStatusIcon &&
        renderHelpIcon(
          `Mapping the ${option.label} ensures that items retain their active, completed or archived states between tools.`,
        )}
      {showColumnIcon &&
        renderHelpIcon(
          `Mapping the ${option.label} ensures that items retain their location, stage or other designation you have setup in your tool’s workflow.`,
        )}
      {showUserFieldIcon &&
        renderHelpIcon(
          `In order for ${option.label} to appear in synced ${itemNamePlural}, they must exist with an identical email address in both tools`,
        )}
    </MaxWidthBox>
  );
};

const renderToggleButtonIcon = (name, disabled, direction, fieldAssociationNameA, fieldAssociationNameB) => {
  const text = `The fields you connected can only be mapped ${
    direction === fieldTypes.TARGET.BOTH ? 'two-ways' : 'one-way'
  }.`;
  return (
    <Tooltip placement="top" content={text} forceHide={!disabled}>
      <Icon
        name={name}
        data-testid={`${fieldAssociationNameA}-${fieldAssociationNameB}-${name}`}
        aria-label={disabled ? text : undefined}
      />
    </Tooltip>
  );
};

function getTarget({ fieldAssociation, selectOptionField, containerSide, otherSide, syncDirection, otherSideField }) {
  const { target } = fieldAssociation;
  if (selectOptionField.semantic === fieldTypes.SEMANTIC.SUBTASKS) {
    return syncDirection;
  }

  if (
    selectOptionField.semantic === fieldTypes.SEMANTIC.ATTACHMENTS &&
    otherSideField?.get('type') === fieldTypes.TYPES.STRING &&
    selectOptionField.type === fieldTypes.TYPES.ITEM
  ) {
    return otherSide;
  }

  if (
    selectOptionField.type === fieldTypes.TYPES.STRING &&
    otherSideField?.get('semantic') === fieldTypes.SEMANTIC.ATTACHMENTS &&
    otherSideField.get('type') === fieldTypes.TYPES.ITEM
  ) {
    return containerSide;
  }

  if (selectOptionField.readOnly) {
    return otherSide;
  }

  if (selectOptionField.kind === fieldTypes.KINDS.PCD_COMMON) {
    return containerSide;
  }

  return target;
}

function useGetItemsNames() {
  const [capabilitiesV3A, capabilitiesV3B] = useGetCapabilitiesV3();
  return [
    capabilitiesV3A.getIn(['item', 'names', 'singular']),
    capabilitiesV3A.getIn(['item', 'names', 'plural']),
    capabilitiesV3B.getIn(['item', 'names', 'singular']),
    capabilitiesV3B.getIn(['item', 'names', 'plural']),
  ];
}

export const FieldAssociationItemInputs = ({
  containerIdA,
  containerIdB,
  index,
  isEntityAlreadyMapped,
  isCustomFieldLimitReached,
  isFieldMappingLimitReached,
  providerIdentityIdA,
  providerIdentityIdB,
  providerNameA,
  providerNameB,
  directionDisabled,
  fieldAssociation,
  disabled,
  itemTypeA,
  itemTypeB,
}) => {
  const { register, setValue, watch } = useFormContext();
  const fieldA = useSelector((state) =>
    getField(state, {
      containerSide: 'A',
      containerId: containerIdA,
      fieldId: fieldAssociation.A?.field,
      kind: fieldAssociation.A?.kind,
      providerIdentityId: providerIdentityIdA,
      providerName: providerNameA,
      itemType: itemTypeA,
    }),
  );
  const fieldB = useSelector((state) =>
    getField(state, {
      containerSide: 'B',
      containerId: containerIdB,
      fieldId: fieldAssociation.B?.field,
      kind: fieldAssociation.B?.kind,
      providerIdentityId: providerIdentityIdB,
      providerName: providerNameB,
      itemType: itemTypeB,
    }),
  );
  const isCustomFieldsBasedA = useSelector((state) => isProviderItemCustomFieldBased(state, providerNameA, itemTypeA));
  const isCustomFieldsBasedB = useSelector((state) => isProviderItemCustomFieldBased(state, providerNameB, itemTypeB));

  const orgPlanFeatureUsage = useSelector(getOrganizationPlanFeaturesWithUsage);
  const capabilitiesFieldTypes = useSelector(getCapabilitiesFieldTypes);
  const organizationId = useSelector(getSelectedOrganizationId);
  const isOrgTrialing = useSelector((state) => isOnFreeTrial(state, organizationId));
  const isSingleAndMultiMappingFlagEnabled = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.SINGLE_AND_MULTIPLE_SELECT_MAPPING_ENABLED, organizationId),
  );
  const syncDirection = watch('syncDirection');

  const [, itemNamePluralA, , itemNamePluralB] = useGetItemsNames();

  const handleFieldOnChange = async (fieldId, selectOptionField, containerSide) => {
    setValue(`associations.${index}.${containerSide}.kind`, selectOptionField.kind, { shouldDirty: true });

    const otherSide = fromOtherSide(containerSide);
    const target = getTarget({
      fieldAssociation,
      selectOptionField,
      containerSide,
      otherSide,
      syncDirection,
      otherSideField: containerSide === 'A' ? fieldB : fieldA,
    });

    setValue(`associations.${index}.target`, target, { shouldDirty: true });
    setValue(
      `associations.${index}.${containerSide}`,
      { field: fieldId, kind: selectOptionField.kind },
      { shouldDirty: true },
    );
    setValue(`associations.${index}.${otherSide}.mappingCategory`, undefined, { shouldDirty: true });
    setValue(`associations.${index}.${otherSide}.mapping`, undefined, { shouldDirty: true });
  };

  return (
    <Box p={[tokens.spacing.s2, 0]} alignItems="center">
      <GrowingBox>
        <PCDFieldsSelect
          name={`associations.${index}.A.field`}
          value={fieldAssociation.A?.field}
          itemType={itemTypeA}
          containerId={containerIdA}
          containerSide="A"
          disabled={disabled}
          displayType={displayTypes.MAPPING}
          providerIdentityId={providerIdentityIdA}
          providerName={providerNameA}
          onChange={(fieldId, field) => handleFieldOnChange(fieldId, field, 'A')}
          isOptionDisabledHandler={(field) =>
            isOptionDisabled({
              orgPlanFeatureUsage,
              isEntityAlreadyMapped,
              isCustomFieldLimitReached,
              isFieldMappingLimitReached,
              target: fieldAssociation.target,
              fieldEntity: field,
              otherFieldEntity: fieldB,
              containerSide: 'A',
              capabilitiesFieldTypes,
              isSingleAndMultiMappingFlagEnabled,
            })
          }
          placeholder="Select a field"
          searchPlaceholder="Search for a specific field"
          formatLabel={(option) =>
            formatLabel(option, {
              otherFieldType: fieldB.get('type'),
              containerSide: 'A',
              target: fieldAssociation.target,
              providerName: providerNameA,
              isOrgTrialing,
              customFieldsBased: isCustomFieldsBasedA,
              itemNamePlural: itemNamePluralA,
            })
          }
        />
      </GrowingBox>
      <Box m={[0, tokens.spacing.s4]} p={[tokens.spacing.s2, tokens.spacing.s4]}>
        <Radio.Group
          name={`associations.${index}.target`}
          disabled={disabled}
          buttonStyle="solid"
          size="middle"
          value={fieldAssociation.target}
          onChange={(event) => setValue(`associations.${index}.target`, event.target.value, { shouldDirty: true })}
          optionType="button"
          options={[
            {
              value: 'B',
              disabled: directionDisabled && fieldAssociation.target !== 'B',
              label: renderToggleButtonIcon(
                'long-arrow-right',
                directionDisabled && fieldAssociation.target !== 'B',
                fieldAssociation.target,
                fieldAssociation.A.field,
                fieldAssociation.B.field,
              ),
            },
            {
              value: 'both',
              disabled: directionDisabled && fieldAssociation.target !== fieldTypes.TARGET.BOTH,
              label: renderToggleButtonIcon(
                'exchange',
                directionDisabled && fieldAssociation.target !== fieldTypes.TARGET.BOTH,
                fieldAssociation.target,
                fieldAssociation.A.field,
                fieldAssociation.B.field,
              ),
            },
            {
              value: 'A',
              disabled: directionDisabled && fieldAssociation.target !== 'A',
              label: renderToggleButtonIcon(
                'long-arrow-left',
                directionDisabled && fieldAssociation.target !== 'A',
                fieldAssociation.target,
                fieldAssociation.A.field,
                fieldAssociation.B.field,
              ),
            },
          ]}
        />
      </Box>
      <GrowingBox>
        <PCDFieldsSelect
          disabled={disabled}
          name={`associations.${index}.B.field`}
          value={fieldAssociation.B?.field}
          itemType={itemTypeB}
          containerId={containerIdB}
          containerSide="B"
          providerIdentityId={providerIdentityIdB}
          providerName={providerNameB}
          displayType={displayTypes.MAPPING}
          onChange={(fieldId, field) => handleFieldOnChange(fieldId, field, 'B')}
          isOptionDisabledHandler={(field) =>
            isOptionDisabled({
              orgPlanFeatureUsage,
              isEntityAlreadyMapped,
              isCustomFieldLimitReached,
              target: fieldAssociation.target,
              fieldEntity: field,
              otherFieldEntity: fieldA,
              containerSide: 'B',
              capabilitiesFieldTypes,
              isSingleAndMultiMappingFlagEnabled,
            })
          }
          placeholder="Select a field"
          searchPlaceholder="Search for a specific field"
          formatLabel={(option) =>
            formatLabel(option, {
              otherFieldType: fieldA.get('type'),
              containerSide: 'B',
              target: fieldAssociation.target,
              providerName: providerNameB,
              isOrgTrialing,
              customFieldsBased: isCustomFieldsBasedB,
              itemNamePlural: itemNamePluralB,
            })
          }
        />
      </GrowingBox>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register(`associations.${index}.A.kind`)} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register(`associations.${index}.B.kind`)} />
    </Box>
  );
};

const fieldAssociationPropTypes = PropTypes.shape({
  A: PropTypes.shape({ field: PropTypes.string, kind: PropTypes.string }),
  B: PropTypes.shape({ field: PropTypes.string, kind: PropTypes.string }),
  target: PropTypes.oneOf(Object.values(fieldTypes.TARGET)),
});

FieldAssociationItemInputs.propTypes = {
  index: PropTypes.number.isRequired,
  isCustomFieldLimitReached: PropTypes.bool.isRequired,
  isFieldMappingLimitReached: PropTypes.bool.isRequired,
  isEntityAlreadyMapped: PropTypes.func.isRequired,
  providerIdentityIdA: PropTypes.string.isRequired,
  providerIdentityIdB: PropTypes.string.isRequired,
  providerNameA: PropTypes.string.isRequired,
  providerNameB: PropTypes.string.isRequired,
  containerIdA: PropTypes.string.isRequired,
  containerIdB: PropTypes.string.isRequired,
  itemTypeA: PropTypes.string.isRequired,
  itemTypeB: PropTypes.string.isRequired,
  directionDisabled: PropTypes.bool.isRequired,
  fieldAssociation: fieldAssociationPropTypes.isRequired,
  fieldAssociations: PropTypes.arrayOf(fieldAssociationPropTypes).isRequired,
  disabled: PropTypes.bool.isRequired,
};
