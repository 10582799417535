import { useSelector } from 'react-redux';
import { useIsModernIntegration } from '~/containers/FlowBuilder/hooks/useIsModernIntegration';
import { getProviderByName } from '~/reducers';

export const useGetContainerPath = (currentSide) => {
  const provider = useSelector((state) => getProviderByName(state, currentSide.providerName));

  const isModernIntegration = useIsModernIntegration(provider);
  let path = currentSide.containerPath;

  if (isModernIntegration && !!currentSide.containerNativeId) {
    path = currentSide.containerNativeId;
  }

  return path;
};
