import React from 'react';
import { useParams } from 'react-router-dom';
import { useFormContext, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { Box, tokens } from '@unitoio/mosaic';

import { otherSide } from '~/utils/otherSide';
import * as pcdFilterOperatorTypes from '~/consts/pcdFilterOperator';
import * as linkTypes from '~/consts/link';
import * as featureTypes from '~/consts/features';
import { FeatureFlag } from '~/components/FeatureFlag/FeatureFlag';
import { FeatureFlagVariant } from '~/components/FeatureFlag/FeatureFlagVariant';

import { getAreModernRulesEnabled } from '../../../utils/getAreModernRulesEnabled';
import { useGetAnomaliesByPage, PAGES } from '../../../hooks/useGetAnomalies';
import { ClassicIntegrationRules } from './ClassicIntegrationRules/ClassicIntegrationRules';
import { SyncStatusPageAlert } from '../../../components/SyncStatusPageAlert';
import { SectionHeader } from './SectionHeader/SectionHeader';
import { IfStatementRuleRow } from './IfStatementRuleRow/IfStatementRuleRow';
import { ModernIntegrationRules } from './ModernIntegrationRules/ModernIntegrationRules';
import { OnFilterOutRule } from './OnFilterOutRule/OnFilterOutRule';

export const RulesSection = ({ isLoading, isAutoSaving, linkState, side, sides }) => {
  const { register } = useFormContext();

  const { linkId } = useParams();
  const otherContainerSide = otherSide(side);
  const isSideInitialized = useWatch({ name: `${side}.filtersInitializedAt` });
  const [errorsByPage, warningsByPage] = useGetAnomaliesByPage(linkId, PAGES.RULES);
  const shouldRenderSyncStatus =
    !errorsByPage.filter((anomaly) => anomaly.getIn(['additionalErrorData', 'side']) === side).isEmpty() ||
    !warningsByPage.filter((anomaly) => anomaly.getIn(['additionalErrorData', 'side']) === side).isEmpty();

  return (
    <Box
      borderRadius={tokens.spacing.s4}
      borderColor={tokens.colors.content.neutral.n10}
      p={[isLoading ? tokens.spacing.s5 : tokens.spacing.s3, tokens.spacing.s6, tokens.spacing.s5, tokens.spacing.s6]}
      m={[tokens.spacing.s6, 0, 0, 0]}
      aria-label={`${sides[side].containerName} -> ${sides[otherContainerSide].containerName}`}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register(`${side}.filtersInitializedAt`)} defaultValue={isSideInitialized} />

      <SectionHeader
        containerName={sides[side].containerName}
        containerUrl={sides[side].containerUrl}
        providerName={sides[side].providerName}
        otherContainerSection={sides[otherContainerSide]}
      />

      {shouldRenderSyncStatus && (
        <SyncStatusPageAlert key={`sync_status_rules_${side}`} page={PAGES.RULES} side={side} />
      )}

      <Box>
        <IfStatementRuleRow
          providerName={sides[side].providerName}
          providerDisplayName={sides[side].providerDisplayName}
          containerName={sides[side].containerName}
          itemType={sides[side].itemType}
          containerType={sides[side].containerType}
          containerId={sides[side].containerId}
        />
        <FeatureFlag name={featureTypes.FEATURES.CONTAINER_BUILDER}>
          <FeatureFlagVariant value={true}>
            <>
              {getAreModernRulesEnabled(sides[side]) ? (
                <ModernIntegrationRules isAutoSaving={isAutoSaving} isLoading={isLoading} side={side} sides={sides} />
              ) : (
                <ClassicIntegrationRules isLoading={isLoading} linkState={linkState} side={side} sides={sides} />
              )}
            </>
          </FeatureFlagVariant>
          <FeatureFlagVariant value={false}>
            <ClassicIntegrationRules isLoading={isLoading} linkState={linkState} side={side} sides={sides} />
          </FeatureFlagVariant>
        </FeatureFlag>
        <FeatureFlag name={featureTypes.FEATURES.DELETE_PRESERVE_OUT_OF_SYNC_ITEM_FILTER}>
          <FeatureFlagVariant value={true}>
            <OnFilterOutRule
              side={{
                providerName: sides[side].providerName,
                containerId: sides[side].containerId,
                itemType: sides[side].itemType,
              }}
              otherContainerSide={{
                side: otherContainerSide,
                providerName: sides[otherContainerSide].providerName,
                containerId: sides[otherContainerSide].containerId,
                itemType: sides[otherContainerSide].itemType,
                containerType: sides[otherContainerSide].containerType,
              }}
            />
          </FeatureFlagVariant>
        </FeatureFlag>
      </Box>
    </Box>
  );
};

const filterValueType = PropTypes.oneOfType([
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
  PropTypes.bool.isRequired,
]);

const filterShape = PropTypes.shape({
  fieldId: PropTypes.string,
  _id: PropTypes.string,
  id: PropTypes.string,
  isSetDefault: PropTypes.bool,
  kind: PropTypes.string,
  operator: PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator)),
  type: PropTypes.string,
  value: PropTypes.oneOfType([filterValueType, PropTypes.arrayOf(filterValueType)]),
});

const groupedFieldShape = PropTypes.shape({
  canBeAllowed: PropTypes.bool.isRequired,
  canBeDenied: PropTypes.bool.isRequired,
  existingOperators: PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator)).isRequired,
  nbRulesForField: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(filterValueType),
});

RulesSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAutoSaving: PropTypes.bool.isRequired,
  linkState: PropTypes.oneOf(Object.values(linkTypes.LINK_STATES)).isRequired,
  side: PropTypes.oneOf(['A', 'B']).isRequired,
  sides: PropTypes.shape({
    A: PropTypes.shape({
      actions: PropTypes.shape({
        append: PropTypes.func.isRequired,
        fields: PropTypes.arrayOf(filterShape).isRequired,
        insert: PropTypes.func.isRequired,
        move: PropTypes.func.isRequired,
        prepend: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        swap: PropTypes.func.isRequired,
        update: PropTypes.func.isRequired,
      }).isRequired,
      append: PropTypes.func.isRequired,
      canCloseTasks: PropTypes.bool.isRequired,
      containerId: PropTypes.string.isRequired,
      containerName: PropTypes.string.isRequired,
      containerUrl: PropTypes.string.isRequired,
      deepFilters: PropTypes.shape({
        append: PropTypes.func.isRequired,
        fields: PropTypes.arrayOf(filterShape).isRequired,
        insert: PropTypes.func.isRequired,
        move: PropTypes.func.isRequired,
        prepend: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        swap: PropTypes.func.isRequired,
        update: PropTypes.func.isRequired,
      }).isRequired,
      defaultDeepFilterItemFieldId: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(filterShape).isRequired,
      groupedActionFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }).isRequired,
      groupedDeepFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }),
      groupedFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }).isRequired,
      hasDeepFiltering: PropTypes.bool.isRequired,
      hasRequiredDate: PropTypes.bool.isRequired,
      hasSubfolders: PropTypes.bool.isRequired,
      insert: PropTypes.func.isRequired,
      move: PropTypes.func.isRequired,
      prepend: PropTypes.func.isRequired,
      providerDisplayName: PropTypes.string.isRequired,
      providerFields: PropTypes.instanceOf(Map).isRequired,
      providerIdentityId: PropTypes.string.isRequired,
      providerName: PropTypes.string.isRequired,
      remove: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      swap: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
    }).isRequired,
    B: PropTypes.shape({
      actions: PropTypes.shape({
        append: PropTypes.func.isRequired,
        fields: PropTypes.arrayOf(filterShape).isRequired,
        insert: PropTypes.func.isRequired,
        move: PropTypes.func.isRequired,
        prepend: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        swap: PropTypes.func.isRequired,
        update: PropTypes.func.isRequired,
      }).isRequired,
      append: PropTypes.func.isRequired,
      canCloseTasks: PropTypes.bool.isRequired,
      containerId: PropTypes.string.isRequired,
      containerName: PropTypes.string.isRequired,
      containerUrl: PropTypes.string.isRequired,
      deepFilters: PropTypes.shape({
        append: PropTypes.func.isRequired,
        fields: PropTypes.arrayOf(filterShape).isRequired,
        insert: PropTypes.func.isRequired,
        move: PropTypes.func.isRequired,
        prepend: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        swap: PropTypes.func.isRequired,
        update: PropTypes.func.isRequired,
      }).isRequired,
      defaultDeepFilterItemFieldId: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(filterShape).isRequired,
      groupedActionFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }).isRequired,
      groupedDeepFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }),
      groupedFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }).isRequired,
      hasDeepFiltering: PropTypes.bool.isRequired,
      hasRequiredDate: PropTypes.bool.isRequired,
      hasSubfolders: PropTypes.bool.isRequired,
      insert: PropTypes.func.isRequired,
      move: PropTypes.func.isRequired,
      prepend: PropTypes.func.isRequired,
      providerDisplayName: PropTypes.string.isRequired,
      providerFields: PropTypes.instanceOf(Map).isRequired,
      providerIdentityId: PropTypes.string.isRequired,
      providerName: PropTypes.string.isRequired,
      remove: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      swap: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};
