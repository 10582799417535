import { Map } from 'immutable';
import { useSelector } from 'react-redux';

import { getProviderByProviderIdentityId, getProviderVisibleFields } from 'reducers';

export function useGetField({ providerIdentityId, fieldId, itemType, containerId }) {
  const provider = useSelector((state) => getProviderByProviderIdentityId(state, providerIdentityId));
  const fields = useSelector((state) => getProviderVisibleFields(state, provider.get('_id'), itemType, containerId));
  return fields.get(fieldId, Map());
}
